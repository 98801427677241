import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../theme/blocks.css"
import "../theme/block-theme.css"
import "../components/theme.css"

import { Base } from "../utils/animations"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO slug="privacy-policy" title="Privacy Policy" />
      <Base className="page-wrapper privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
          Investment advisers are required by law to inform their clients of
          their policies regarding privacy of client information. We are bound
          by professional standards of confidentiality that are even more
          stringent than those required by law.
        </p>

        <p>
          Federal law gives the customer the right to limit some but not all
          sharing of personal information. It also requires us to tell you how
          we collect, share, and protect your personal information.
        </p>

        <strong>
          TYPES OF NONPUBLIC PERSONAL INFORMATION (NPI) WE COLLECT
        </strong>

        <p>
          We collect nonpublic personal information about you that is either
          provided to us by you or obtained by us with your authorization. This
          can include but is not limited to your Social Security Number, Date of
          Birth, Banking Information, Financial Account Numbers and/or Balances,
          Sources of Income, and Credit Card Numbers or Information. When you
          are no longer our customer, we may continue to share your information
          only as described in this notice.
        </p>

        <strong>PARTIES TO WHOM WE DISCLOSE INFORMATION</strong>

        <p>
          All Investment Advisers may need to share personal information to run
          their everyday business. In the section below, we list the reasons
          that we may share your personal information:
        </p>
        <ul>
          <li>
            For everyday business purposes – such as to process your
            transactions, maintain your account(s), respond to court orders and
            legal investigations, or report to credit bureaus;
          </li>

          <li>
            For our marketing – to offer our products and services to you;
          </li>

          <li>For joint marketing with other financial companies;</li>

          <li>
            For our affiliates’ everyday business purposes – information about
            your transactions and experiences; or
          </li>

          <li>For non-affiliates to market to you.</li>
        </ul>

        <p>
          Clients may opt out of sharing information for joint marketing to
          other financial companies, to our affiliates and to non-affiliates. If
          you are a new customer we may begin sharing your information on the
          day you sign our agreement. When you are no longer our customer, we
          may continue to share your information as described in this notice.
          However, you can contact us at any time to limit our sharing.
        </p>

        <strong>
          PROTECTING THE CONFIDENTIALITY OF CURRENT AND FORMER CLIENT’S
          INFORMATION
        </strong>

        <p>
          To protect your personal information from unauthorized access and use,
          we use security measures that comply with federal law, including
          computer safeguards and secured files and building.
        </p>

        <strong>
          FEDERAL LAW GIVES YOU THE RIGHT TO LIMIT SHARING – OPTING OUT
        </strong>

        <p>
          Federal law allows you the right to limit the sharing of your NPI by
          “opting-out” of the following: sharing for non-affiliates’ everyday
          business purposes – information about your creditworthiness; sharing
          with affiliates who use your information to market to you; or sharing
          with non-affiliates to market to you. State laws and individual
          companies may give you additional rights to limit sharing. Please
          notify us immediately if you choose to opt out of these types of
          sharing.
        </p>

        <p>
          <strong>DEFINITIONS:</strong>
          Affiliates – companies related by common ownership or control. They
          can be financial and non-financial companies; Non-affiliates –
          companies not related by common ownership or control. They can be
          financial and non-financial companies; Joint marketing – a formal
          agreement between non-affiliated financial companies that together
          market financial products or services to you.
        </p>

        <strong>
          Please call if you have any questions. Your privacy, our professional
          ethics, and the ability to provide you with quality financial services
          are very important to us.
        </strong>
      </Base>
    </Layout>
  )
}

export default PrivacyPolicy
